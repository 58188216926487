import clsx from "clsx";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useFlexSearch } from "react-use-flexsearch";
import { useDebounce } from "rooks";
import Input from "../Input";
import Typography from "../Typography";

const BlogSearch = ({ index, store }) => {
  const currentDate = new Date();
  const [searchQuery, setSearchQuery] = useState("");
  const [isFocused, setIsFocused] = useState(false);
  const setDebouncedSearchQuery = useDebounce(setSearchQuery, 250);

  const handleChange = (event) => {
    setDebouncedSearchQuery(event.target.value);
  };

  const results = useFlexSearch(searchQuery, index, store, { limit: 5 });

  return (
    <div className="position-relative mb-32">
      <Input
        onChange={handleChange}
        placeholder="Search for..."
        label="search"
        showLabel={false}
        id="search"
        name="search"
        onFocus={() => setIsFocused(true)}
        onBlur={() => setTimeout(() => setIsFocused(false), 500)}
        autocomplete="off"
      />
      <div className={clsx("search-results-dropdown", { open: isFocused && results.length > 0 })}>
        {results.map(({ slug, title, date }) => {
          const formattedDate = new Date(date);
          return (
            <Link to={slug} className="search-results-item">
              <Typography variant="paragraph-1" weight="semi-bold" color="black">
                {title}
              </Typography>
              <Typography variant="paragraph-2" color="neutral-50">
                {Intl.DateTimeFormat("en-us", {
                  day: "numeric",
                  month: "short",
                  year: formattedDate.getFullYear() !== currentDate.getFullYear() ? "numeric" : undefined,
                }).format(formattedDate)}
              </Typography>
            </Link>
          );
        })}
      </div>
    </div>
  );
};

BlogSearch.propTypes = {
  index: PropTypes.string,
  store: PropTypes.string,
};

BlogSearch.defaultProps = {
  index: "",
  store: "",
};

export default BlogSearch;
