import { graphql, Link } from "gatsby";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import BlogSearch from "../../components/BlogSearch";
import Button from "../../components/Button";
import Divider from "../../components/Divider";
import Grid from "../../components/Grid";
import Input from "../../components/Input";
import Layout from "../../components/Layout";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import Typography from "../../components/Typography";

const Index = ({ location, data }) => {
  const { allMarkdownRemark, localSearchPages } = data;
  const { index, store } = localSearchPages;
  const latestPost = allMarkdownRemark.edges[0].node;
  const latestPostDate = new Date(latestPost.frontmatter.date);
  const currentDate = new Date();
  const [posts, setPosts] = useState([]);
  const [pageNum, setPageNum] = useState(1);
  const recommendedPosts = posts.filter((post) => post.node.frontmatter.recommended);

  const handleShowMore = () => {
    setPageNum(pageNum + 1);
  };

  useEffect(() => {
    setPosts(allMarkdownRemark.edges);
  }, []);

  return (
    <Layout location={location}>
      <Helmet
        title="Linear Finance Blog"
        meta={[
          {
            name: "description",
            content: `Read writing from Linear Finance. A Decentralized Delta-One Asset Protocol with Unlimited Liquidity.`,
          },
        ]}
      />
      <div className="blog-signup-header">
        <Grid container>
          <Grid row align="center">
            <Grid column xs={12} md={5}>
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 384 72">
                <path
                  fill="#050E26"
                  fillRule="evenodd"
                  d="M100.6 29c3.2 0 5.7 1 7.5 3.2l.3.3v-2.8h6.2v24h-6.2v-2.8l-.3.3a9.5 9.5 0 0 1-7.5 3.2c-3.2 0-6-1.3-8.2-3.7a12.7 12.7 0 0 1-3.5-9c0-3.6 1.2-6.6 3.5-9 2.3-2.5 5-3.7 8.2-3.7Zm-26.9 0a11 11 0 0 1 8.7 3.7 12.7 12.7 0 0 1 3.3 10.9v.6H67.5c.8 3 3 4.5 6.7 4.5 2.2 0 4-.6 5.1-2l.3-.4h6a8 8 0 0 1-1.2 3 12.1 12.1 0 0 1-10.3 5c-4 0-7.1-1.1-9.5-3.5a12.3 12.3 0 0 1-3.6-9.1A12.2 12.2 0 0 1 73.8 29Zm-67-11.4v28c0 1 .7 1.8 1.7 1.8h11v6.3H3.6C1.6 53.7 0 52 0 50V17.6h6.6Zm22.3 12v24h-6v-24h6Zm19-.6c2.6 0 4.8.9 6.5 2.7a10 10 0 0 1 2.6 7.3v14.7h-6.2V39.3c0-1.4-.5-2.5-1.3-3.2-.9-.9-2-1.3-3.4-1.3a5 5 0 0 0-3.9 1.5c-1 1-1.4 2.4-1.4 4.4v13h-6.2v-24h6.2v2.7l.2-.4c1.5-2 3.8-3 7-3Zm85.7.2v7l-.5-.1c-1.7-.1-3.2.3-4.6 1.2-1.5 1-2.2 2.6-2.2 5v11.4h-6.2v-24h6.2v4.1l.1-.4a6.4 6.4 0 0 1 2.7-3 8.7 8.7 0 0 1 4.5-1.2Zm-32 5.7a6.7 6.7 0 0 0-6.6 6.8 6.4 6.4 0 0 0 6.6 6.8 6.5 6.5 0 0 0 6.7-6.8 6.5 6.5 0 0 0-6.7-6.8Zm-28-.3a6 6 0 0 0-6.2 4.4v.4h12.2a5.8 5.8 0 0 0-6-4.8Z"
                  clipRule="evenodd"
                />
                <path fill="url(#a)" d="M39.9 17.8a6 6 0 0 1-5.6 6H22.5v-.2a6 6 0 0 1 5.6-6h11.8v.2Z" />
                <path
                  fill="#1A38F8"
                  d="M165.7 36.6a8.5 8.5 0 0 1 4.1 7.6 9 9 0 0 1-3 7A11.3 11.3 0 0 1 159 54h-14.1V20.4H158c3 0 5.6.9 7.6 2.7 2 1.7 3 4 3 6.9 0 2.7-1 4.9-2.9 6.6Zm-7.7-9h-5.4v6h5.4c.9 0 1.6-.3 2.1-.9a3 3 0 0 0 .9-2.1 2.9 2.9 0 0 0-3-3Zm1 19.2a3 3 0 0 0 2.3-.9c.6-.6.9-1.4.9-2.3a3 3 0 0 0-.9-2.3 3 3 0 0 0-2.3-1h-6.4v6.5h6.4Zm14.4 7.2V19h7.2v35h-7.2Zm33-3a12.4 12.4 0 0 1-9 3.7 12 12 0 0 1-9-3.7 12.3 12.3 0 0 1-3.7-9 12 12 0 0 1 3.7-9c2.4-2.4 5.5-3.7 9-3.7 3.6 0 6.6 1.3 9 3.7a12 12 0 0 1 3.7 9 12 12 0 0 1-3.7 9Zm-13-5c1.1 1.1 2.4 1.7 4 1.7s2.9-.6 4-1.6c1-1 1.5-2.4 1.5-4.1a5 5 0 0 0-1.6-4c-1-1.1-2.3-1.7-3.9-1.7s-2.9.6-4 1.6c-1 1-1.5 2.4-1.5 4.1 0 1.7.5 3 1.6 4Zm38.4-16h7v22.8c0 3.8-1.2 6.6-3.7 8.5a14 14 0 0 1-9 3c-5.4 0-9.1-2-11.3-5.8l6.1-3.6c1.1 2 3 3 5.5 3 1.7 0 3-.4 4-1.3s1.4-2.1 1.4-3.8v-2.2c-1.6 2-4 3.1-7 3.1-3.3 0-6.2-1.2-8.4-3.5a12.2 12.2 0 0 1-3.4-8.7 12 12 0 0 1 3.4-8.6c2.2-2.4 5-3.6 8.4-3.6 3 0 5.4 1 7 3.1V30Zm-10 15.6a6 6 0 0 0 4.2 1.5 6 6 0 0 0 4.2-1.5c1-1 1.6-2.4 1.6-4 0-1.7-.5-3-1.6-4.1A6 6 0 0 0 226 36c-1.7 0-3 .5-4.2 1.5-1 1-1.6 2.4-1.6 4 0 1.7.5 3 1.6 4Z"
                />
                <defs>
                  <linearGradient id="a" x1="38.8" x2="23.6" y1="20.3" y2="20.3" gradientUnits="userSpaceOnUse">
                    <stop stopColor="#7EB5FF" />
                    <stop offset="1" stopColor="#1A38F8" />
                  </linearGradient>
                </defs>
              </svg>
              <h1 className="sr-only">Linear Blog</h1>
              <Typography variant="subtitle" component="p">
                Stay up to date with our announcements, development updates and other news!
              </Typography>
            </Grid>
            <Grid column xs={12} md={5} className="mx-auto">
              <Typography variant="subtitle" component="p">
                Sign up to our newsletter to receive the latests updates.
              </Typography>
              <form
                className="d-flex mt-8"
                action="https://finance.us7.list-manage.com/subscribe/post?u=32fc7c972574ff3b667d37599&amp;id=2fd9a15e72"
                method="post"
                target="_blank"
                rel="noreferrer"
              >
                <div style={{ position: "absolute", left: -5000 }} aria-hidden="true">
                  <input type="text" name="b_32fc7c972574ff3b667d37599_2fd9a15e72" tabIndex="-1" value="" />
                </div>
                <Input
                  id="newsletter-email"
                  name="EMAIL"
                  type="email"
                  className="form-control"
                  placeholder="My email address"
                  showLabel="false"
                />
                <Button className="flex-shrink-0 ml-8">Sign up</Button>
              </form>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <Grid container>
        <Grid row>
          <Grid column xs={12} md={8}>
            <Link to={latestPost.frontmatter.slug} className="text-decoration-none">
              <div className="post-image mb-32">
                <PreviewCompatibleImage
                  imageInfo={{ image: latestPost.frontmatter.banner, alt: latestPost.frontmatter.title }}
                  alt={latestPost.frontmatter.title}
                />
              </div>
              <Typography variant="h2" className="mb-4">
                {latestPost.frontmatter.title}
              </Typography>
              <Typography variant="paragraph-1" color="neutral-50" className="mb-8">
                {Intl.DateTimeFormat("en-us", {
                  day: "numeric",
                  month: "short",
                  year: latestPostDate.getFullYear() !== currentDate.getFullYear() ? "numeric" : undefined,
                }).format(latestPostDate)}{" "}
                · {latestPost.timeToRead} min read {(latestPost.frontmatter.categories || []).length > 0 ? "·" : null}{" "}
                {(latestPost.frontmatter.categories || []).join(" ,")}
              </Typography>
              <Typography variant="paragraph-1" color="neutral-50" className="mb-8">
                {latestPost.excerpt}
              </Typography>
              <Typography variant="paragraph-1" color="primary" weight="bold">
                Read all
              </Typography>
            </Link>
          </Grid>
          <Grid column xs={12} md={4}>
            <BlogSearch index={index} store={store} />
            <div className="blog-recommended-container">
              <Typography variant="h2" className="mb-24">
                Recommended stories
              </Typography>
              {recommendedPosts.slice(0, 5).map(({ node }) => {
                const postDate = new Date(node.frontmatter.date);
                const dateString = Intl.DateTimeFormat("en-us", {
                  day: "numeric",
                  month: "short",
                  year: postDate.getFullYear() !== currentDate.getFullYear() ? "numeric" : undefined,
                }).format(postDate);

                return (
                  <Link to={node.frontmatter.slug} key={node.id}>
                    <Typography variant="subtitle" color="black" className="mb-4 mt-16">
                      {node.frontmatter.title}
                    </Typography>
                    <Typography color="neutral-50">
                      {dateString} · {node.timeToRead} min read
                    </Typography>
                  </Link>
                );
              })}
            </div>
          </Grid>
        </Grid>
        <Divider className="mt-40 mb-32" />
        <div className="d-flex mb-40">
          <Button>all</Button>
        </div>
        <Typography variant="h1" className="mb-24">
          Latest stories
        </Typography>
        <Grid row>
          {([...posts].slice(0, pageNum * 9) || []).map(({ node }) => {
            const postDate = new Date(node.frontmatter.date);
            const dateString = Intl.DateTimeFormat("en-us", {
              day: "numeric",
              month: "short",
              year: postDate.getFullYear() !== currentDate.getFullYear() ? "numeric" : undefined,
            }).format(postDate);

            return (
              <Grid column xs={12} md={4} key={node.id} className="mb-32">
                <Link to={node.frontmatter.slug} className="text-decoration-none">
                  <div className="post-image mb-8">
                    <PreviewCompatibleImage
                      imageInfo={{ image: node.frontmatter.banner, alt: node.frontmatter.title }}
                      alt={node.frontmatter.title}
                      loading="lazy"
                    />
                  </div>
                  <Typography variant="h6" className="mb-8">
                    {node.frontmatter.title}
                  </Typography>
                  <Typography variant="paragraph-1" color="neutral-50">
                    {dateString} · {node.timeToRead} min read{" "}
                    {(node.frontmatter.categories || []).length > 0 ? "·" : null}{" "}
                    {(node.frontmatter.categories || []).join(" ,")}
                  </Typography>
                </Link>
              </Grid>
            );
          })}
        </Grid>
        <div className="d-flex justify-content-center mb-40">
          {posts.length > pageNum * 9 && <Button onClick={handleShowMore}>Show more</Button>}
        </div>
      </Grid>
    </Layout>
  );
};

Index.propTypes = {
  data: PropTypes.object,
  location: PropTypes.object,
};

Index.defaultProps = {
  data: {},
  location: undefined,
};

export default Index;

export const pageQuery = graphql`
  {
    localSearchPages {
      index
      store
    }
    allMarkdownRemark(
      filter: { frontmatter: { slug: { regex: "/blog/" } } }
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          id
          timeToRead
          excerpt(pruneLength: 300)
          frontmatter {
            slug
            banner
            description
            title
            date
            recommended
            categories
          }
        }
      }
    }
  }
`;
